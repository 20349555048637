export const PAYMENT_TERMS_TEMPLATE = `
  <style>
      @font-face {
        font-family: 'InterVariable';
        src: url('https://cdn.shopify.com/static/fonts/inter/v4/InterVariable.woff2') format('woff2');
        font-weight: 100 900;
        font-display: swap;
        font-style: normal;
        font-named-instance: 'Regular';
    }

    * {
      box-sizing: border-box;
    }

    .visually-hidden {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    .shopify-installments {
      margin-top: 0;
      margin-bottom: 0;
    }

    .shopify-installments__inline {
      display: inline
    }

    .shopify-installments__content {
      padding-right: 4px;
    }

    .shopify-installments__learn-more {
      color: inherit;
      font-weight: inherit;
      font: inherit;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      text-decoration: underline;
    }

    .shopify-installments__prequal-row-wrapper {
      display: inline-flex;
      position: relative;
      overflow: hidden;
    }

    .shopify-installments__prequal-row {
      display: inline-flex;
      width: fit-content;
      column-gap: 4px;
      flex-wrap: nowrap;
      min-width: max-content;
      align-items: center;
      transition: width 200ms;
    }

    .shopify-installments__prequal-row shop-prequal-amount,
    .shopify-installments__prequal-row .shopify-installments__learn-more {
      display: inline-block;
    }

    .default-font #shopify-installments-content b {
      font-family: 'InterVariable', sans-serif;
      font-weight: bold;';
    }

    #prequalAmountRowWrapper:not(.is-prequalified) #prequalAmountContainer {
      transform: translateY(100%);
      position: absolute;
    }

    .is-prequalified #prequalAmountContainer {
      animation-name: slideUp;
      animation-duration: 300ms;
      animation-timing-function: ease-in;
    }
    .is-prequalified #prequalBackupCTA {
      transition: all 300ms ease-out;
      transform: translateY(100%);
      opacity: 0;
      position: absolute;
    }

    .stable.is-prequalified #prequalBackupCTA,
    #prequalAmountRowWrapper.stable:not(.is-prequalified) #prequalAmountContainer
    {
      visibility: hidden;
    }

    #prequalAmountRowWrapper:not(.is-prequalified) #prequalAmountContainer button,
    .is-prequalified #prequalBackupCTA button {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;
    }

    @keyframes slideUp {
      0%,
      20% {
        transform: translateY(100%);
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  </style>
  <p class="shopify-installments" id="shopify-installments">
    <span class="shopify-installments__content" id="shopify-installments-content"></span>
    <span class="shopify-installments__prequal-row-wrapper stable" id="prequalAmountRowWrapper">
      <span class="shopify-installments__prequal-row" id="prequalBackupCTA">
        <button aria-haspopup="dialog" class="shopify-installments__learn-more" id="shopify-installments-cta"></button>
      </span>
      <span class="shopify-installments__prequal-row" id="prequalAmountContainer">
        <button aria-haspopup="dialog" class="shopify-installments__learn-more" id="shopifyPrequalifiedCTA" tabIndex="-1"></button>
      </span>
    </span>
  </p>
`;

export const SHOP_PAY_LOGO = (backgroundColor: string) =>
  `<shop-pay-logo role="img" aria-label="Shop Pay" background-color="${backgroundColor}"></shop-pay-logo>`;

export const SPLIT_PAY_ELIGIBLE = (price: string, backgroundColor: string) => `
Pay in 4 interest-free installments of <b>${price}</b> with <shop-pay-logo role="img" aria-label="Shop Pay" background-color="${backgroundColor}"></shop-pay-logo>
`;

export const INTEREST_ONLY_ELIGIBLE = (backgroundColor: string) => `
Split your purchase into monthly installments with <shop-pay-logo role="img" aria-label="Shop Pay" background-color="${backgroundColor}"></shop-pay-logo>
`;

export const DYNAMIC_INTEREST_ONLY_ELIGIBLE = (
  price: string,
  backgroundColor: string,
) => `
From <b class="shopify-installments-price">${price}</b>/mo with <shop-pay-logo role="img" aria-label="Shop Pay" background-color="${backgroundColor}"></shop-pay-logo>
`;

export const PAY_IN_4_OR_AS_LOW_AS_ELIGIBLE = (
  price: string,
  backgroundColor: string,
) => `4 interest-free installments, or from <b>${price}</b>/mo with <shop-pay-logo role="img" aria-label="Shop Pay" background-color="${backgroundColor}"></shop-pay-logo>
`;

export const ZERO_INTEREST_ELIGIBLE = (
  price: string,
  backgroundColor: string,
  isFinancingTermApr0: boolean,
) => `
From <b>${price}</b>/mo ${
  isFinancingTermApr0 ? 'at' : 'or'
} 0% APR with <shop-pay-logo role="img" aria-label="Shop Pay" background-color="${backgroundColor}"></shop-pay-logo>
`;

export const NON_ELIGIBLE_MIN = (minPrice: string, backgroundColor: string) => `
Pay in 4 interest-free installments for orders over <b>${minPrice}</b> with <shop-pay-logo role="img" aria-label="Shop Pay" background-color="${backgroundColor}"></shop-pay-logo>
`;

export const NON_ELIGIBLE_MONTHLY_PAYMENTS_MIN = (
  minPrice: string,
  backgroundColor: string,
) => `
Split your purchase into monthly installments for orders over <b>${minPrice}</b> with <shop-pay-logo role="img" aria-label="Shop Pay" background-color="${backgroundColor}"></shop-pay-logo>
`;

export const NON_ELIGIBLE_MAX = (maxPrice: string, backgroundColor: string) => `
Split your purchase into installments for orders up to <b>${maxPrice}</b> with <shop-pay-logo role="img" aria-label="Shop Pay" background-color="${backgroundColor}"></shop-pay-logo>
`;

const CA_RESIDENTS_NOTICE =
  'CA Residents: Loans by Affirm Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.';

const RATES_FROM_APR =
  'Rates from 0-36% APR. Payment options through Affirm are subject to an eligibility check, may not be available in all states, and are provided by these lending partners: <a class="help_text__link" href="https://www.affirm.com/licenses" target="_blank" aria-describedby="shopify-payment-terms-modal-warning-text">affirm.com/lenders</a>. Options depend on your purchase amount, and a down payment may be required.';

export const LEGAL_COPY = {
  INTEREST_AND_SPLIT_PAY: `The estimated payment amount excludes taxes and shipping. ${RATES_FROM_APR} ${CA_RESIDENTS_NOTICE}`,
  INTEREST_ONLY: `${RATES_FROM_APR} ${CA_RESIDENTS_NOTICE}`,
  SPLIT_PAY_ONLY: `The estimated payment amount excludes taxes and shipping. Payment options are offered by Affirm and are subject to an eligibility check and might not be available in all states. ${CA_RESIDENTS_NOTICE}`,
  INELIGIBLE: `Payment options are offered by Affirm and are subject to an eligibility check and might not be available in all states. ${CA_RESIDENTS_NOTICE}`,
  DYNAMIC_PDP: `The estimated payment amount excludes taxes and shipping. Rates range from 0-36% APR. Payment options through Shop Pay Installments are subject to an eligibility check and are provided by these lending partners: <a class="help_text__link" href="https://www.affirm.com/lenders" target="_blank" aria-describedby="shopify-payment-terms-modal-warning-text">affirm.com/lenders</a>. Options depend on your purchase amount, and a down payment may be required. More options may be available upon approval. State notices to consumers: <a class="help_text__link" href="https://www.affirm.com/licenses" target="_blank" aria-describedby="shopify-payment-terms-modal-warning-text">affirm.com/licenses</a>.`,
};

export const MODAL_SUBTITLE = {
  INTEREST_AND_SPLIT_PAY: (splitPayLoanRepayment: string) =>
    `Choose your payment schedule at checkout &#8211; starting at 4 interest-free payments of <span class="tagline__bold">${splitPayLoanRepayment}</span> every 2 weeks.`,
  INTEREST_ONLY: () =>
    `Choose your payment schedule at checkout to split your purchase into monthly installments.`,
  SPLIT_PAY_ONLY: (splitPayLoanRepayment: string) =>
    `Select installments at checkout to split your purchase into 4 interest-free payments of <span class="tagline__bold">${splitPayLoanRepayment}</span> every 2 weeks.`,
  INELIGIBLE_MIN: (minPrice: string) =>
    `For orders over ${minPrice}, select installments at checkout to split your purchase into 4 interest-free payments.`,
  INELIGIBLE_MONTHLY_PAYMENTS_MIN: (minPrice: string) =>
    `For orders over ${minPrice}, select installments at checkout to split your purchase into monthly payments.`,
  INELIGIBLE_MAX: (maxPrice: string) =>
    `For orders up to ${maxPrice}, select installments at checkout to split your purchase into multiple payments.`,
  DYNAMIC_PDP: (numberOfPlans: number, priceWithoutInterest: string) =>
    `Sample ${
      numberOfPlans === 1 ? 'plan' : 'plans'
    } for <b>${priceWithoutInterest}</b> purchase`,
};

export const AFFIRM = 'Affirm';

export const SHARED_MODAL_STYLES = `
#shopify-payment-terms-modal .visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

#shopify-payment-terms-modal .modal-wrapper {
  max-width: 432px;
}

#shopify-payment-terms-modal p {
  font-size: 18px;
  line-height: 150%;
}

#shopify-payment-terms-modal section {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

#shopify-payment-terms-modal .price {
  color: rgb(90, 49, 244);
  font-weight: 600;
  display: inline-flex;
  flex-direction: column;
  line-height: 1;
  align-items: center;
}

#shopify-payment-terms-modal .price__icon {
  width: 43px;
  height: 10px;
}

#shopify-payment-terms-modal .list {
  list-style: none;
  padding: 0;
  margin: 0;
}

#shopify-payment-terms-modal .list-item__subheading {
  font-size: 14px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.7);
}

#shopify-payment-terms-modal .btn__close--icon {
  display: inline-flex;
  flex-shrink: 0;
  padding: 16px;
  border-radius: 6px;
}

#shopify-payment-terms-modal .btn__close--icon:hover,
#shopify-payment-terms-modal .btn__close--icon:active,
#shopify-payment-terms-modal .btn__close--icon:focus {
  background-color: rgb(244, 241, 254);
  color: rgb(63, 34, 171);
}

#shopify-payment-terms-modal .help_text {
  margin-bottom: 19px;
  margin-top: 16px;
  font-size: 11px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.6);
}

#shopify-payment-terms-modal .help_text small {
  color: inherit;
  letter-spacing: 0.04rem;
  font-weight: 300;
  font-size: 11px;
}

#shopify-payment-terms-modal .help_text__link {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}

#shopify-payment-terms-modal .help_text__link:hover,
#shopify-payment-terms-modal .help_text__link:active,
#shopify-payment-terms-modal .help_text__link:focus {
  color: rgb(63, 34, 171);
}

#shopify-payment-terms-modal .tagline__bold {
  font-weight: 700;
}

#shopify-payment-terms-modal footer, #shopify-payment-terms-cover footer {
  text-align: center;
}

#shopify-payment-terms-modal .shop-pay-logo-wrapper,
#shopify-payment-terms-cover .shop-pay-logo-wrapper {
  margin-bottom: 12px;
}

#shopify-payment-terms-modal .close__icon {
  width: 13px;
  height: 13px;
}

.affirm-logo,
#shopify-payment-terms-modal .affirm-logo,
#shopify-payment-terms-cover .affirm-logo {
  width: 39px;
  height: 21px;
  padding-left: 4px;
  box-sizing: content-box;
}

.affirm-logo-inline,
#shopify-payment-terms-modal .affirm-logo-inline,
#shopify-payment-terms-cover .affirm-logo-inline {
  margin-bottom: -6px;
  padding-left: 0px;
}

.affirm-text,
#shopify-payment-terms-modal .affirm-text,
#shopify-payment-terms-cover .affirm-text {
  display: flex;
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}

.affirm-text small,
#shopify-payment-terms-modal .affirm-text small,
#shopify-payment-terms-cover .affirm-text small {
  font-size: 11px;
  letter-spacing: 0.04rem;
  line-height: 14px;
}
`;

export const FAILED_TO_LOAD_HTML_ERROR_MESSAGE =
  "Failed to construct 'HTMLElement': This instance is already constructed";

export const BANNER_UNSUPPORTED_FOR_THEME_MESSAGE =
  '[Shop Pay Installments] Cart price updates may not be handled automatically for this theme. To ensure the price shown in the Shop Pay Installments banner is updated correctly, follow the instructions found here: https://shopify.dev/themes/pricing-payments/installments#updating-the-banner-with-cart-total-changes';
